.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Use viewport height to cover the entire screen */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white; /* Background color for the modal */
    max-width: 600px; /* Adjust the max width of the modal */
    width: 70%;
    height: 70vh; /* Use viewport height to control the height */
    /* padding: 10px; */
    border-radius: 10px;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); Box shadow for the modal */
  }
  
  /* Add additional styling as needed */
  
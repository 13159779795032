


.App, body {
  text-align: center;
  font-family: 'Avenir' !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.wheelBgCircle{
  position: relative;
  border: 6px solid #9d8073;
  border-radius: 100%;
  -webkit-box-shadow: inset 0px 0px 11px 0px rgba(0,0,0);
-moz-box-shadow: inset 0px 0px 11px 0px rgba(0,0,0);
box-shadow: inset 0px 0px 11px 0px rgb(0 0 0);
}

.submitButton {
  background-color: transparent;
  color:#273533;
  border: 2px solid rgb(158, 128, 115);
  min-width: 220px;
  width: auto;
  padding: 0px 15px;
  margin: 20px;
  height: 50px;
  min-height: 50px;
  text-decoration: none !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 48px;
  display: inline-block;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.App-link {
  color: #61dafb;
}

/* .spinner {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 2px solid black;
  position: relative;
  overflow: hidden;
} */

/* .segment {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 150px 75px;
} */





.segment:nth-child(1) { background-image: url('../src/assets/images/circ.png'); }
.segment:nth-child(2) { transform: rotate(36deg); }
.segment:nth-child(3) { transform: rotate(72deg); }
.segment:nth-child(4) { transform: rotate(108deg); }
.segment:nth-child(5) { transform: rotate(144deg); }
.segment:nth-child(6) { transform: rotate(180deg); }
.segment:nth-child(7) { transform: rotate(216deg); }
.segment:nth-child(8) { transform: rotate(252deg); }
.segment:nth-child(9) { transform: rotate(288deg); }
.segment:nth-child(10) { transform: rotate(324deg); }


.full-wheel {
  /* Add background color */
  /* background-color: red; */
  /* background-image: url('../src/assets/images/tri.png'); */
  /* Add border */
  /* border: 2px solid #ccc; */
  /* Add border-radius to make it round */
  border-radius: 50%;
  /* Add box shadow for depth */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Add similar styles for other segment classes */



.pointer {
  position: absolute;
  top: 31%; /* Position the pointer in the middle vertically */
  left: 50%; /* Position the pointer in the middle horizontally */
  transform: translate(-50%, -50%); /* Center the pointer */
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  background-image: url('../src/assets/images/pin.png'); /* Replace with the path to your pointer image */
  background-position: center top; /* Adjust vertical positioning */
  background-size: cover;
  z-index: 2; /* Ensure the pointer is on top of the wheel */
}

.wheel {
  width: 800px;
  height: 800px;
  background-image: url('../src/assets/images/spin.png');  
  background-size: cover; /* Adjusts the size of the background image to cover the entire container */
  background-position: center; /* Centers the background image */
  border-radius: 50%;
  margin: 10px;
  cursor: pointer;
}

.bg-image {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('../src/assets/images/spin2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.wheel-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* Ensure the wheel appears on top of the background */
}

.spinning {
  animation: spin 1s ease-out forwards; /* Ensure the animation is defined properly */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* For mobile devices */
@media only screen and (max-width: 600px) {
  #canvas {
    width: 90vw;
    height: 90vw;
  }
}

/* For mobile view */
@media only screen and (max-width: 767px) {
  .submitButton {
    font-size: 18px;
    min-width: 180px;
   
  }
}




.bg {
    background: #e3e2d7;
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .bg::before {
    background: url(../assets/img/butterfly.png);
    background-size: contain !important;
    background-position: top center;
    background-repeat: no-repeat;
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 10%;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  
  .overBg {
    background: url(../assets/img/tex-bg.png);
    width: 100%;
    min-height: 20%;
    background-size: cover !important;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
  }
  
  .overBg h1 {
    font-family: 'avenirblack';
    font-size: 50px;
    color: #9d8073;
    line-height: 64px;
    margin: 20px 0 0;
    padding: 0;
  }
  
  .overBg h2 {
    font-family: 'avenirblack';
    font-size: 40px;
    color: #273533;
    margin: 0 0 10px;
    padding: 0;
  }
  
  .overBg p {
    font-size: 15px;
    color: #273533;
  }
  
  .partBtn {
    border: 1px solid #9d8073;
    font-size: 18px;
    color: #273533;
    padding: 12px 25px;
    font-weight: 400;
    text-decoration: none;
    margin: 10px 0;
    display: inline-block;
  }
  
  .bgSpace {
    padding: 2% 15px 15px 20px;
    text-align: center;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set the initial height to occupy the full viewport height */
}

.spinner-container img {
    max-width: 100%;
    max-height: 100%; /* Ensure the image doesn't exceed the container's height */
}
  
  @media (max-width: 768px) {
    .bgSpace {
        padding: 5% 15px 15px 20px;
        text-align: center;
    }
  
    .overBg h1 {
      font-size: 36px; /* Decrease font size for desktop view */
      line-height: 38px; /* Adjust line height for desktop view */
    }
  
    .overBg h2 {
      font-size: 28px; /* Decrease font size for desktop view */
      margin-bottom: 5px; /* Adjust margin for desktop view */
    }
  
    .overBg p {
      font-size: 14px; /* Decrease font size for desktop view */
    }
  }
  
@font-face {
  font-family: 'Avenir';
  src: url('../src/assets/font/Avenir-Roman.woff2') format('woff2'),
      url('../src/assets/font/Avenir-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'avenirblack';
  src: url('../src/assets/font/avenir_black-webfont.woff2') format('woff2'),
      url('../src/assets/font/avenir_black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Avenir' !important;
}

.inputStyle{
  color:#273533;
  width: 450px;
  max-width: 85%;
    height: 35px;
    padding: 10px;
    margin-top: 20px;
    border: 2px solid rgb(158, 128, 115);
    background-color: transparent;
    border-radius: 0px;
    text-align: left;
    font-size: 23px;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
